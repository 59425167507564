import moment from "moment";

export default function () {
  const dateFormat = (value) => {
    if (value) {
      return moment(String(value)).format("DD/MM/YYYY");
    }
  };
  const timeFormat = (value) => {
    if (value) {
      return moment(String(value)).format("hh:mm A");
    }
  };
  const timeFormat24 = (value) => {
    if (value) {
      return moment(String(value)).format("HH:mm");
    }
  };
  const dateFormatInput = (value) => {
    if (value) {
      return moment(String(value)).format("YYYY-MM-DD");
    }
  };
  const timeFormatInput = (value) => {
    if (value) {
      return moment(String(value)).format("HH:mm");
    }
  };
  const getImg = (img) => {
    return img ? process.env.VUE_APP_URL_IMG + img : null;
  };
  const getProgressBarr = (valor, total) => {
    let resul = (valor / total) * 100;
    return resul;
  };
  const validarSolonumeros = (event) => {
    if (
      (event.keyCode < 48 || event.keyCode > 57) &&
      (event.keyCode < 96 || event.keyCode > 105) &&
      event.keyCode !== 190 &&
      event.keyCode !== 110 &&
      event.keyCode !== 8 &&
      event.keyCode !== 9
    ) {
      return false;
    }
    return true;
  };

  const formatearNumero = (nStr) => {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "," + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, "$1" + "." + "$2");
    }
    return x1 + x2;
  };

  const ConvertStringtoDecimal = (cod) => {
    let x = cod.split(",");
    let e = x[0];
    let d = x[1] ? "," + x[1] : "";
    e = e.replace(".", "");
    e = e.replace(".", "");
    e = e.replace(".", "");
    e = e.replace(".", "");
    d = d.replace(",", ".");
    var numero = e + d;
    return parseFloat(numero).toFixed(2);
  };

  const onPegarTexto = async () => {
    let texto = "";
    await navigator.clipboard.readText().then((res) => {
      texto = res;
    });
    return texto;
  };

  return {
    dateFormat,
    timeFormat,
    dateFormatInput,
    timeFormatInput,
    getImg,
    getProgressBarr,
    timeFormat24,
    validarSolonumeros,
    formatearNumero,
    ConvertStringtoDecimal,
    onPegarTexto,
  };
}
