<template>
  <div class="caja">
    <div v-for="d of lstDashboard" v-bind:key="d.id">
      <CardDashboard :obj="d" @click="page(d.url)" />
    </div>  
  </div>
</template>

<script>
import { computed } from "vue-demi";
import { useStore } from "vuex";
import CardDashboard from "../components/CardDashboard.vue";
export default {
  components: {    
    CardDashboard,
  },
  methods: {
    page(url) {
      this.$router.push(url);      
    },
  },
  setup() {
    const store = useStore();
    let objDashboard = { url: "/api/v1/getDashboard", set: "setDashboard" };
    store.dispatch("getData", objDashboard);
    const lstDashboard = computed(() => store.state.dashboard);
    return { lstDashboard };
  },
};
</script>

<style>
.caja {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.caja > div:hover {
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 0px 15px 15px #ec731e;
  -webkit-box-shadow: 0px 0px 15px 15px #ec731e;
}
.caja > div {
  width: 280px;
  height: 200px;
  margin: 30px;
  cursor: pointer;
  box-shadow: 2px 0px 32px 0px rgba(214, 199, 199, 0.41);
  -webkit-box-shadow: 2px 0px 32px 0px rgba(214, 199, 199, 0.41);
  -moz-box-shadow: 2px 0px 32px 0px rgba(214, 199, 199, 0.41);
}

@media (max-width: 800px) {
  .caja > div {
    width: 120px !important;
    height: 160px !important;
    background: red;
  }
  .info-box-text {
    font-size: 12px !important;
    font-weight: bold !important;
  }
  .info-box-number {
    font-size: 10px !important;
  }
}

.info-box-text {
  font-size: 28px;
}
.info-box-content {
  text-align: center;
}
</style>